.tag {
  @apply text-white text-sm leading-7 rounded-xl py-2 px-4 flex items-center; 

  > img {
    @apply ml-1 h-6;
  }

  &:hover {
    @apply opacity-50;
  }

  &:disabled:hover {
    @apply opacity-100;
  }
}

.tag-green {
  @extend .tag;
  @apply bg-green
}

.tag-gray {
  @extend .tag;
  @apply bg-gray-4;
}

.tag-red {
  @extend .tag;
  @apply bg-red;
}

.tag-blue {
  @extend .tag;
  @apply bg-primary;
}

.tag-dark-blue {
  @extend .tag;
  @apply bg-primary-dark;
}

.tag-light-blue {
  @extend .tag;
  @apply text-primary bg-primary-x-light;
}


