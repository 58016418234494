.home-section {
  @apply max-w-screen-xs w-full mx-auto flex flex-col items-center py-16 rounded-md relative;

  @include media($md){
    @apply border border-primary;
  }
}

.home-section .decoration {
  @apply absolute w-48 top-16 -left-48 hidden items-center justify-end bg-white;

  @include media($md) {
    @apply flex;
  }
}
