.show-event-container {
  @apply fixed w-screen h-full justify-center items-center z-50 top-0 left-0 bg-background-blue;

  @include media($xl) {
    @apply static w-1/2 pt-24 bg-transparent;
  }
}

.show-event-card {
  @apply border border-primary rounded-lg bg-white max-w-192 mx-auto;
}
