.sidebar {
  @apply fixed h-full top-0 flex items-stretch z-40 transition-all;

  > .toggle {
    @apply absolute top-0 right-full bg-white p-4 flex justify-center items-center;

    @include media($xl) {
      @apply hidden;
    }
  }
}

.language-selector {
  @apply flex items-center uppercase text-base leading-8 uppercase;

  &.unauthenticated {
    @apply text-primary-dark;
  }

  &.authenticated {
    @apply text-white;
  }
}

.language-selector-drop {
  @apply absolute top-full right-0 w-24 p-6 z-5;

  &.authenticated {
    @apply text-primary-dark bg-white z-30;
  }

  &.unauthenticated {
    @apply text-white bg-primary-dark;
  }

  > button {
    @apply py-2;
  }
}
