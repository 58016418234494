.year {
  @apply w-full max-w-screen-xs mb-4;

  .year-title {
    @apply py-2 border-b-2 border-primary-dark;
  }
}

.year.filtered {
  .year-title {
    @apply border-gray-4 text-gray-4;
  }
}

.month {
  @apply flex;

  .month-title {
    @apply min-w-24 w-24 border-r-2 border-primary-dark py-8;
  }
}

.month.filtered {
  .month-title {
    @apply border-gray-4;
  
    > p {
      @apply text-gray-4;
    }
  }
}

.event {
  @apply p-4 border rounded-md w-full relative cursor-pointer mb-6 border-primary;


  &.draft {
    @apply border-red;
  }

  .major-category {
    @apply mb-1;

    &.filtered {
      @apply text-gray-4;
    }
  }

  .minor-category.filtered {
    @apply text-gray-4;
  }

  .draft-event {
    @apply pt-4 text-gray-2 text-right;
  }

  .serviced {
    @apply flex-1 flex items-center;
  }

  .image {
    @apply flex justify-end;
  }
}

.event.filtered {
  
  @apply border-gray-4;

  &.isDraft {
    @apply border-gray-4;
  }
  
  .mileage {
    @apply text-gray-4;
  }

  .draft-event {
    @apply text-gray-4;
  }

  .serviced {
    @apply flex-1 flex items-center;

    > img {
      @apply opacity-40;
    }

    > p {
      @apply text-gray-4;
    }
  }

  .image {
    @apply opacity-40;
  }
}

.year.white, 
.year.white.filtered {
  .year-title {
    @apply border-primary-light text-white;
  }

  .month,
  .month.filtered {
    .month-title {
      @apply border-primary-light;
    
      > p {
        @apply text-white;
      }
    }
  }

  .event,
  .event.filtered {
    @apply border-primary-light;

    &.draft {
      @apply border-red;
    }

    .major-category,
    .major-category.filtered {
      @apply text-primary;
    }

    .minor-category,
    .minor-category.filtered {
      @apply text-primary-light;
    }

    .mileage {
      @apply text-primary-light;
    }

    .draft-event {
      @apply text-gray-3;
    }
  }
}


