.modal-background {
  @apply fixed w-screen flex h-full bg-background justify-center items-center z-50 left-0 transition-all duration-200;
}

.modal {
  @apply w-9/10 max-w-192 min-h-64 rounded-md bg-primary-x-light pt-16 relative flex flex-col items-center;
}

.calendly {
  @apply w-9/10 max-w-192 fixed bottom-4 right-4 bg-primary-x-light py-16 px-2;

  @include media($xl) {
    @apply right-36;
  }
}

.modal-alert {
  @apply w-12 h-12 mb-6;
}

.modal-message {
  @apply text-center mb-12 px-16
}

.modal-close-tag {
  @apply absolute top-8 right-8;
}

.modal-close-tag-icon {
  @apply w-6 h-6;
}

.modal-dont-show-again {
  @apply bg-primary-light py-8 w-full rounded-b-md px-16;
}
