.btn {
  @apply flex justify-center items-center font-serif font-semibold max-w-full;
}

.btn-small {
  @extend .btn;
  @apply w-64 rounded-xl text-sm leading-7 py-4 px-2;
}

.btn-medium {
  @extend .btn;
  @apply w-128 rounded-xl text-base leading-8 py-8 px-4;
}

.btn-large {
  @extend .btn;
  @apply w-192 rounded-2xl text-base leading-8 py-8 px-6;
}

.btn-regular {
  @apply bg-primary text-white;

  &:disabled {
    @apply bg-primary-light;
  }

  &:hover, &:active {
    @apply bg-primary-dark;
  }

  &:disabled {
    &:hover, &:active {
      @apply bg-primary-light;
    }
  }
}

.btn-outline {
  @apply shadow-outline text-primary;

  &:disabled {
    @apply shadow-outline-disabled text-primary-light;
  }

  &:hover, &:active {
    @apply shadow-outline-hover text-primary-dark;
  }

  &:disabled {
    &:hover, &:active {
      @apply shadow-outline-disabled text-primary-light;
    }
  }
}

.btn-alert {
  @apply bg-red text-white;
}

.btn-icon {
  @apply w-48 inline-block;

  img {
    @apply w-32 mx-auto;
  }
}

.btn-icon.small {
  @apply w-6;

  img {
    @apply w-6;
  }
}
