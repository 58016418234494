.form-input {
  @apply w-full max-w-192 mx-auto relative min-h-32 mb-4;

  label {
    @apply absolute top-2 left-6 text-sm leading-7 text-primary; 
  }

  input {
    @apply w-full pt-8 pb-2 text-base leading-12 px-8 mb-2 border-b border-primary-dark bg-transparent;

    &::placeholder{
      @apply text-primary;
    }
  }
}

.form-input.with-bg {
  input {
    @apply bg-primary-x-light rounded-md border-b-0;

    &::placeholder {
      @apply text-primary-light;
    }

    &:focus {
      @apply shadow-input;
    }
  }

  label {
    @apply text-primary-light;
  }
}

.form-input.checkbox {
  @apply min-h-12;

  label {
    @apply static flex-1;
  }

  input {
    @apply inline-block mb-0 p-0 w-6 h-6;
  }

  .left {
    @apply flex flex-row-reverse items-center;

    input {
      @apply mr-4;
    }
  }

  .right {
    @apply flex items-center;

    input {
      @apply ml-4;
    }

    label {
      @apply text-right;
    }
  }
}
.form-input.add-title {
  @apply min-h-auto; 

  > label {
    @apply hidden;
  }

  > input {
    @extend .one-line-medium;
    @extend .montserrat-bold;
    @apply border-0 p-0;

    &::placeholder {
      @apply text-red;
    }
  }
}
.switch-input {
  @apply w-full max-w-192 mx-auto relative min-h-32 mb-4 flex items-center justify-between py-6 px-2;

  .switch {
    @apply relative inline-block w-32 h-16;

    .slider {
      transition: all 0.5s ease;
      @apply w-32 h-16 absolute cursor-pointer top-0 left-0 bg-primary-x-light rounded-2xl;
    }

    .slider::before{
      content: '';
      transition: all 0.5s ease;
      @apply absolute w-12 h-12 left-2 bottom-2 bg-white rounded-full;
    }

    input:checked + .slider {
      transition: all 0.5s ease;
      @apply bg-primary;
    }

    input:checked + .slider::before {
      transition: all 0.5s ease;
      @apply left-auto right-2;
    }
  }
}
.switch-input.draft-mode {
  @apply mx-0 justify-end w-full mb-0;

  .switch {
    @apply mr-2;
  }
}

.form-input.checkbox.tips {
  @apply min-h-auto mb-0;
}

.form-vehicle-input {
  @apply flex w-full max-w-256;
}

.vehicle-icon {
  @apply flex items-center w-32 min-w-32 max-w-32 max-h-24 justify-end;
}

.form-vehicle-input .form-input {
  @apply mx-0;
}

.form-vehicle-input .form-input.part-number {
  @apply max-w-32 min-w-32 w-32 flex items-center justify-end max-h-24 min-h-auto m-0;

  label {
    @apply hidden;

    &.icon {
      @apply block static w-16 h-16;

      > .badge {
        @apply absolute top-8 right-0;
      }

      > .camera {
        @apply w-16 h-16 cursor-pointer;
      }
    }
  }

  > p {
    @apply hidden;
  }

  .gallery {
    @extend .has-bg-image;
    @apply w-16 h-16 cursor-pointer;
  }
}

.form-input.profile {

  > label {
    @apply static px-2 cursor-pointer;

    > p {
      @apply text-base leading-12;
    }
  }

  label.icon {
    @apply flex w-full items-center justify-center bg-primary-x-light border border-primary-dark py-4 rounded-md cursor-pointer;
  }

  .gallery {
    @extend .has-bg-image;
    @apply w-full h-128 mb-6 cursor-pointer;
    
    img {
      @apply mx-auto;
    }
  }
}

.form-input.multiple {
  @apply max-h-none;

  > label {
    @apply block static text-base leading-12 top-0 left-0 border-b mb-6 px-8 pb-2 pt-8 w-full;
  }

  .gallery {
    @extend .has-bg-image;
    @apply w-16 h-16 mr-1 cursor-pointer;
  }
}

.info .form-input.multiple {
  @apply mx-0;
}

.form-input.event-input {
  @apply mx-0;

  textarea {
    @apply w-full pt-8 pb-2 text-base leading-12 px-8 mb-2 border-b border-primary-dark h-32 max-h-32 min-h-32 bg-transparent;

    &::placeholder{
      @apply text-primary;
    }

    &:focus {
      outline: none;
    }
  }
}

.form-input.event-input.highlight {
  label {
    @apply static text-base leading-8;
  }

  img {
    @apply w-8 h-8 ml-4;
  }
}

input[type="date"]:before{
  content: attr(placeholder);

  @include media($xl) {
    content: '';
  }
}


