#main-wrapper {
  @apply flex flex-col w-screen min-h-screen overflow-hidden;
}

.wrap {
  @apply w-9/10 mx-auto;

  @include media($full) {
    @apply w-full max-w-screen-hd;
  }
}

.expand {
  @apply flex-1 flex flex-col;
}

.right-graphic {
  @apply bg-cover bg-right-top w-1/2 fixed top-0 right-0 h-full hidden;
  z-index: -1;

  @include media($lg) {
    @apply block;
  }
}

.back-arrow {
  @apply w-16 h-16 rounded-full flex items-center justify-center;

  &:hover {
    @apply bg-primary-x-light;
  }
}
