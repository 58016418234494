$xs: 480px;
$sm: 600px;
$md: 840px;
$lg: 960px;
$xl: 1280px;
$hd: 1440px;
$full: 1600px;

@mixin media($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'core';
@import 'typography';
@import 'components/buttons';
@import 'components/forms';
@import 'components/layout/layout';
@import 'components/layout/sidebar';
@import 'components/slider';
@import 'components/tags';
@import 'components/modals';
@import 'components/balloon';

@import 'authenticated/home/home';
@import 'authenticated/components/page-header';
@import 'authenticated/car/car-page';
@import 'authenticated/car/event-page';
@import 'authenticated/car/events';
@import 'authenticated/car/show-event';
@import 'authenticated/car/galleries';
@import 'authenticated/user/user';
