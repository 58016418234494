.page-header {
  @apply relative flex flex-col justify-center border-b border-primary-light w-full py-6;

  @include media($md) {
    @apply w-9/10 ml-auto max-w-256;
  }

  .graphic {
    @apply absolute w-48 h-48 -top-12 -right-12 max-w-none max-h-none hidden;

    @include media($md) {
      @apply block;
    }
  }
}
