.car-tab {
  @apply w-1/3 flex justify-center text-base leading-8 py-6 max-w-128 truncate;

  @include media($md) {
    @apply text-md;
  }
}

.car-page {
  .timeline, .vehicle-data, .snapshot {
    @apply hidden;
  }

  &.timeline .timeline {
    @apply flex flex-col flex-1;
  }
  &.vehicle-data .vehicle-data {
    @apply flex flex-col flex-1;
  }

  &.snapshot .snapshot {
    @apply flex flex-col flex-1;
  }
}
