body {
  font-size: 16px;
  line-height: 1.5;
  @apply font-sans font-normal text-primary-dark;
}

button:focus, input:focus {
  outline: none;
}

img {
  @apply block w-auto h-auto max-w-full max-h-full;
}

.has-bg-image {
  @apply bg-cover bg-center;
}

.line-scale-pulse-out.loader-screen > div {
  background: #012754 !important;
}
