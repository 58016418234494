.snapshot-selected {
  @extend .has-bg-image;
  @apply w-128 h-128 rounded-sm;

  @include media($xs) {
    @apply w-192 h-192;
  }

  @include media($sm) {
    @apply w-screen-xs h-screen-xs;
  }
}

.snapshot-thumb {
  @extend .has-bg-image;
  @apply rounded-sm mb-4 w-32 h-32;

  @include media($xs) {
    @apply w-48 h-48;
  }

  @include media($sm) {
    @apply w-64 h-64;
  }
}
