.one-line-x-small {
 @apply text-xs leading-4;
}

.text-small {
  @apply text-sm leading-8;
}

.one-line-small {
  @apply text-sm leading-7;
}

.text-regular {
  @apply text-base leading-12;
}

.one-line-regular {
  @apply text-base leading-8;
}

.text-medium {
  @apply text-md leading-16;
}

.one-line-medium {
  @apply text-md leading-12;
}

.text-large {
  @apply text-lg leading-24;
}

.one-line-large {
  @apply text-lg leading-16;
}

.one-line-x-large {
  @apply text-xl leading-24;
}


.open-sans-bold {
  @apply font-bold;
}

.montserrat {
  @apply font-serif font-semibold;
}

.montserrat-bold {
  @apply font-serif font-extrabold;
}
