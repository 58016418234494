.balloon {
  @apply w-full max-w-192 py-8 pl-12 pr-24 relative rounded; 
}

.balloon-close {
  @apply absolute top-4 right-4;
}

.balloon-close-icon {
  @apply w-8 h-8;
}

.balloon > span > img {
  @apply w-16 mr-4;
}

.balloon-close-tag {
  @apply w-8 h-8;
}

.balloon span.with-link {
  @apply mb-12;
}

.balloon.top-right::after,
.balloon.top-left::after,
.balloon.bottom-left::after,
.balloon.top-center::after {
  content: '';
  @apply w-0 h-0 absolute;
}

.balloon.top-right::after,
.balloon.top-left::after,
.balloon.top-center::after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.balloon.bottom-left::after{
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.balloon.top-right.bg-green::after,
.balloon.top-left.bg-green::after,
.balloon.top-center.bg-green::after {
  border-bottom: 8px solid #69DD95;
}

.balloon.top-right.bg-primary-dark::after,
.balloon.top-left.bg-primary-dark::after,
.balloon.top-center.bg-primary-dark::after {
  border-bottom: 8px solid #012754;
}

.balloon.bottom-left.bg-green::after{
  border-top: 8px solid #69DD95;
}

.balloon.bottom-left.bg-primary-dark::after{
  border-top: 8px solid #012754;
}

.balloon.top-right::after,
.balloon.top-left::after,
.balloon.top-center::after {
  @apply -top-4;
}

.balloon.top-right::after{
  @apply right-12;
}

.balloon.top-left::after {
  @apply left-12;
}

.balloon.top-center::after {
  left: calc(50% - 4px);
}

.balloon.bottom-left::after {
  @apply top-full left-12;
}




