.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  opacity: 1 !important;
  

  &:hover, &:active {
    background: transparent !important;
  }
}

.carousel .control-disabled.control-arrow {
  opacity: 1 !important;
}

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  border-top: 24px solid transparent !important;
  border-bottom: 24px solid transparent !important;
}

.carousel .control-next.control-arrow:before {
  border-left: 24px solid #436DA9 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 24px solid #436DA9 !important;
}
.carousel .slide {
  max-height: 90vh;
}

.carousel .slide img {
  width: auto !important;
  display: block !important;
  margin: 0 auto;
  border: 0;
}

.slider-dot {
  @apply w-6 h-6 bg-gray-4 rounded-full mr-2;

  &.selected {
    @apply bg-red
  }
}

.gallery-thumbs {
  @apply flex;

  .gallery-thumb {
    @extend .has-bg-image;
    @apply w-16 h-16 mr-1;
  }
}

.snapshot-thumbs {
  @apply flex flex-wrap justify-around;

  .gallery-thumb {
    @extend .has-bg-image;
    @apply w-64 h-64 mb-4 rounded-sm;

    @include media($md) {
      @apply w-128 h-128;
    }
  }
}

.show-event-thumbs {
  @apply flex flex-wrap relative;

  .gallery-thumb {
    @extend .has-bg-image;
    @apply w-32 h-32 rounded-sm;
    margin: 0 3.8px 8px;
  }
}

.show-thumbs {
  @apply flex flex-wrap justify-center;

  @include media($md) {
    @apply justify-start
  }

  .gallery-thumb {
    @extend .has-bg-image;
    @apply w-128 h-128 mb-4 rounded-md mr-4;
  }
}
